import { animate, animation, style } from '@angular/animations';

export const qaroniEnterFadeInConfig = animation([
  style({ opacity: 0 }),
  animate('{{time}}', style({ opacity: 1 })),
]);

export const qaroniLeaveFadeOutConfig = animation([
  style({ opacity: 1 }),
  animate('{{time}}', style({ opacity: 0 })),
]);
