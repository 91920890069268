<button mat-button type="button" class="close-icon" [mat-dialog-close]="false">
  <mat-icon>close</mat-icon>
</button>
<div mat-dialog-title>{{'Se ha producido un error en la aplicación'|translate}}.</div>
<mat-dialog-content>
  <p>{{'Es posible que el error sea algo temporal, por lo que puedes intentarlo de nuevo'|translate}}.</p>
  <p>
    {{'Si no se soluciona el problema contacta con nosotros'|translate}}.
    <span *ngIf="!showErrorDetails" (click)="onShowErrorDetails()">
      <mat-icon class="more-info-icon" [matTooltip]="'Más información del error'|translate">error_outline</mat-icon>
    </span>
  </p>
  <mat-card *ngIf="showErrorDetails" class="mat-elevation-z0">
    <mat-card-header>
      <mat-card-title>{{'Información técnica del error'|translate}}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <p>
        {{'El siguiente código contiene información útil para nuestros técnicos a la hora de detectar y corregir el error'|translate}}.
      </p>
      <code>
        <div *ngIf="isControlledError" class="mb-3">
          <strong>{{error?.error?.errors[0]?.code}}: {{error?.error?.errors[0]?.title}}</strong>
          <em class="pl-1" *ngIf="error?.error?.errors[0]?.detail">{{error?.error?.errors[0]?.detail}}</em>
        </div>
        <div>
          <strong>Url:</strong> {{error?.url}}
        </div>
        <div>
          <strong>Status:</strong> {{error?.status}}
        </div>
        <div>
          <strong>StatusText:</strong> {{error?.statusText}}
        </div>
        <div>
          <strong>Message:</strong> {{error?.message}}
        </div>
      </code>
    </mat-card-content>
  </mat-card>
  <!-- <pre>{{data|json}}</pre> -->
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button color="warn" type="button" class="m-1" [mat-dialog-close]="true" cdkFocusInitial>
    <mat-icon>error</mat-icon> {{'Cerrar'|translate}}
  </button>
</mat-dialog-actions>
