export enum CardTypeEnum {
  SHOP = 'SHOP',
}

export type CardType =
  | CardTypeEnum.SHOP;

export const CardTypeArray = [
  CardTypeEnum.SHOP,
];

export const CardTypeInfo = [
  {
    [CardTypeEnum.SHOP]: {
      name: 'Minorista',
    },
  },
];
