import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApplicationInterceptor } from './application.interceptor';
import { LoggingInterceptor } from './logging.interceptor';
import { ManageErrorsInterceptor } from './manage-errors.interceptor';
import { OAuthInterceptor } from './o-auth.interceptor';

export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: OAuthInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: LoggingInterceptor, multi: true },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ManageErrorsInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ApplicationInterceptor,
    multi: true,
  },
];
