import { transition, trigger, useAnimation } from '@angular/animations';
import {
  qaroniEnterFadeInConfig,
  qaroniLeaveFadeOutConfig
} from './toggle-fade';

export const qaroniToggleFadeTrigger = trigger('toggleFade', [
  transition(':enter', [
    useAnimation(qaroniEnterFadeInConfig, { params: { time: '350ms' } }),
  ]),
  transition(':leave', [
    useAnimation(qaroniLeaveFadeOutConfig, { params: { time: '350ms' } }),
  ]),
]);
