import { Component, Input, OnInit } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { Card, CardService } from '@qaroni-core/entities';
import { AllAppService } from '@qaroni-core/services';
import { qaroniEnterFadeInTrigger } from '@qaroni-shared/animations';
import { DownloadAndroidDialogComponent } from '../dialogs/download-android-dialog/download-android-dialog.component';

@Component({
  selector: 'qaroni-card-preview',
  templateUrl: './card-preview.component.html',
  styleUrls: ['./card-preview.component.scss'],
  animations: [qaroniEnterFadeInTrigger],
})
export class CardPreviewComponent implements OnInit {
  @Input() card: Card;
  @Input() disabled = false;

  constructor(
    private allApp: AllAppService,
    private cardService: CardService
  ) {}

  ngOnInit(): void {}

  get isActive(): boolean {
    return this.cardService.isActive(this.card);
  }

  get downloadAppleWallet(): string {
    if (this.card?.cardId && this.card?.wallet) {
      return this.cardService.getDownloadAppleWallet(
        this.card?.cardId,
        this.card?.wallet
      );
    }
  }

  get downloadAndroid(): string {
    if (this.card?.cardId && this.card?.wallet) {
      return this.cardService.getDownloadAndroid(
        this.card?.cardId,
        this.card?.wallet
      );
    }
  }

  get downloadPdf(): string {
    if (this.card?.cardId && this.card?.wallet && this.allowPdf) {
      return this.cardService.getDownloadPdf(
        this.card?.cardId,
        this.card?.wallet
      );
    }
  }

  get allowPdf(): boolean {
    return this.cardService.isActive(this.card) && !!this.card?.isStatic;
  }

  public previewPdf(card: Card): string {
    if (this.card?.cardId && this.card?.wallet && this.allowPdf) {
      return this.cardService.getPreviewPdf(card?.cardId, this.card?.wallet);
    }
  }

  public showDownloadAndroidDialog(): void {
    if (this.card?.cardId) {
      const matDialogConfig = new MatDialogConfig();
      matDialogConfig.width = '700px';
      matDialogConfig.maxWidth = '90vw';
      matDialogConfig.panelClass = 'style-close-dialog';
      matDialogConfig.autoFocus = false;
      matDialogConfig.data = this.card;

      const dialog = this.allApp.dialog.open<
        DownloadAndroidDialogComponent,
        string,
        boolean
      >(DownloadAndroidDialogComponent, matDialogConfig);
    }
  }
}
