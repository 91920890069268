export class FilesValidations {
  public static validateImages(files: File[]): boolean {
    if (files.length) {
      for (let index = 0; index < files.length; index++) {
        if (files[index].size > 4000000) {
          return false;
        }
        if (
          files[index].type !== 'image/jpeg' &&
          files[index].type !== 'image/png'
        ) {
          return false;
        }
      }
      return true;
    } else {
      return false;
    }
  }

  public static allowNumberFiles(
    maxFiles: number,
    currentFiles: number,
    files: File[]
  ): File[] {
    if (files.length) {
      if (currentFiles >= maxFiles) {
        return [];
      } else {
        if (files.length <= maxFiles - currentFiles) {
          return files;
        } else {
          files.splice(maxFiles - currentFiles);
          return files;
        }
      }
    } else {
      return [];
    }
  }

  public static allowOneFile(files: File[]): File[] {
    if (files.length) {
      if (files.length === 1) {
        return files;
      } else {
        files.splice(1);
        return files;
      }
    } else {
      return [];
    }
  }

  public static validateDocuments(files: File[]): boolean {
    if (files.length) {
      for (const document of files) {
        if (document.size > 4000000) {
          return false;
        }
        if (document.type !== 'application/pdf') {
          return false;
        }
      }
      return true;
    } else {
      return false;
    }
  }
}
