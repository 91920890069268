import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'qaroni-required-indicator',
  templateUrl: './required-indicator.component.html',
  styleUrls: ['./required-indicator.component.scss'],
})
export class RequiredIndicatorComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
