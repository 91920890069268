import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppUrlsConfig, ImagesConfig } from '@qaroni-core/config';
import { Card, CardService } from '@qaroni-core/entities';

@Component({
  selector: 'qaroni-download-android-dialog',
  templateUrl: './download-android-dialog.component.html',
  styleUrls: ['./download-android-dialog.component.scss'],
})
export class DownloadAndroidDialogComponent implements OnInit {
  ImagesConfig = ImagesConfig;
  AppUrlsConfig = AppUrlsConfig;

  constructor(
    public dialogRef: MatDialogRef<DownloadAndroidDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public card: Card,
    private cardService: CardService
  ) {}

  ngOnInit(): void {}

  public onCancelClick(): void {
    this.dialogRef.close(false);
  }

  get downloadAndroid(): string {
    if (this.card?.cardId && this.card?.wallet) {
      return this.cardService.getDownloadAppleWallet(
        this.card?.cardId,
        this.card?.wallet
      );
    }
  }
}
