<div @enterFadeIn class="alert alert-danger" role="alert">
  <h4 class="alert-heading font-weight-bold">
    {{ "¡IMPORTANTE!" | translate }}
  </h4>
  <p>
    {{
      "Las aplicaciones Android e iOS de Bonos Consumo Navalmoral son de uso EXCLUSIVO de los establecimientos"
        | translate
    }}.
  </p>
  <p>
    {{
      "Los consumidores no deben instalar la aplicación para hacer uso de los bonos"
        | translate
    }}.
  </p>
</div>
