import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AllAppService, CommonsHttpService } from '@qaroni-core/services';
import { Observable, Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Council } from '../types/council';
import { Province } from '../types/province';
import { AddressHttpService } from './address-http.service';

@Injectable({
  providedIn: 'root',
})
export class AddressService {
  protected readonly provincesSubject = new Subject<Province[]>();
  protected readonly councilsSubject = new Subject<Council[]>();

  constructor(
    private allApp: AllAppService,
    private commonsHttp: CommonsHttpService,
    private addressHttp: AddressHttpService
  ) {}

  private enableLoading(): void {
    this.allApp.progressBar.show();
  }

  private disableLoading(): void {
    this.allApp.progressBar.hide();
  }

  // ==========================================================================================
  // Get Provinces
  // ==========================================================================================

  public getProvinces$(): Observable<Province[]> {
    return this.provincesSubject.asObservable();
  }

  public getProvinces(): void {
    this.enableLoading();
    this.addressHttp
      .getProvinces$()
      .pipe(finalize(() => this.disableLoading()))
      .subscribe({
        next: this.nextGetProvinces,
        error: this.errorGetProvinces,
      });
  }

  private nextGetProvinces = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const provinces: Province[] = data.body.result;
      this.provincesSubject.next(provinces);
    } else {
      this.provincesSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  }

  private errorGetProvinces = (error: HttpErrorResponse): void => {
    this.provincesSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  }

  // ==========================================================================================
  // Get Councils
  // ==========================================================================================

  public getCouncils$(): Observable<Province[]> {
    return this.councilsSubject.asObservable();
  }

  public getCouncils(provinceID: number | string): void {
    this.enableLoading();
    this.addressHttp
      .getCouncils$(provinceID)
      .pipe(finalize(() => this.disableLoading()))
      .subscribe({
        next: this.nextGetCouncils,
        error: this.errorGetCouncils,
      });
  }

  private nextGetCouncils = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const councils: Council[] = data.body.result;
      this.councilsSubject.next(councils);
    } else {
      this.councilsSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  }

  private errorGetCouncils = (error: HttpErrorResponse): void => {
    this.councilsSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  }
}
