import { Injectable } from '@angular/core';
import { AppConfig, LanguageEnum } from '@qaroni-core/types';
import { Observable, Subject } from 'rxjs';
import { StorageUtilsService } from '../storage-utils/storage-utils.service';

@Injectable({
  providedIn: 'root',
})
export class AppStorageService {
  private appConfig: AppConfig;
  protected readonly appConfigSubject = new Subject<AppConfig>();

  private lsKey = 'appConfig';

  constructor(private serviceCommon: StorageUtilsService) {}

  public get$(): Observable<AppConfig> {
    return this.appConfigSubject.asObservable();
  }

  public get(): AppConfig {
    if (this.serviceCommon.validateInLocalStorage(this.lsKey)) {
      return JSON.parse(localStorage.getItem(this.lsKey));
    } else {
      return this.appConfig;
    }
  }

  public set(appConfig: AppConfig): void {
    if (this.serviceCommon.validateLocalStorage) {
      localStorage.setItem(this.lsKey, JSON.stringify(appConfig));
    } else {
      this.appConfig = appConfig;
    }
    this.appConfigSubject.next(appConfig);
  }

  public reset(): void {
    if (this.serviceCommon.validateInLocalStorage(this.lsKey)) {
      localStorage.removeItem(this.lsKey);
    } else {
      this.appConfig = undefined;
    }
    this.appConfigSubject.next(undefined);
  }

  // ==========================================================================================
  // Language config
  // ==========================================================================================

  get hasLanguage(): boolean {
    if (this.get() && this.get().language) {
      return true;
    }
    return false;
  }

  public getLanguage(): LanguageEnum {
    if (this.hasLanguage) {
      if (this.serviceCommon.validateInLocalStorage(this.lsKey)) {
        return JSON.parse(localStorage.getItem(this.lsKey)).language;
      } else {
        return this.appConfig.language;
      }
    } else {
      return LanguageEnum.default;
    }
  }

  public setLanguage(language: LanguageEnum): void {
    if (this.serviceCommon.validateLocalStorage) {
      let appConfig: AppConfig = this.get();
      if (appConfig) {
        appConfig.language = language;
      } else {
        appConfig = { language };
      }
      localStorage.setItem(this.lsKey, JSON.stringify(appConfig));
      this.appConfigSubject.next(appConfig);
    } else {
      this.appConfig.language = language;
      this.appConfigSubject.next(this.appConfig);
    }
  }
}
