<mat-card @enterFadeIn class="text-disabled card-bg-shop">
  <mat-card-content>
    <div class="row justify-content-end align-items-center">
      <div class="col-auto">
        <a
          class="mat-small qaroni-dark-link"
          [routerLink]="['/dashboard/merchants/list']"
          >{{ "Ver establecimientos" | translate }}</a
        >
      </div>
    </div>
    <div class="row">
      <div class="col-12 px-3 mb-1">
        <div class="mat-small">
          {{ "Tarjeta Bono" | translate }}
          {{ cardStatus | cardStatus | lowercase }}
        </div>
      </div>
      <div class="col-12 mb-2">
        <div class="mat-display-1 mb-0 text-center">
          {{ cardBalance | integerToCurrency | currency }}
        </div>
      </div>
      <!-- <div class="col-12 mb-3 text-right">
        <div class="mat-small">
          <div>
            <span>{{ "Vence el" | translate }}: </span>
            <strong>{{ expired | date: "medium" }}</strong>
          </div>
          <div>{{'La tarjeta bono caducará dos mes luego de activarla'|translate}}</div>
        </div>
      </div> -->
      <!-- <div class="col-12">
        <button
          mat-flat-button
          color="shop"
          [disabled]="true"
          (click)="createCard()"
        >
          <mat-icon>add</mat-icon> {{ "Activar Bono" | translate }}
        </button>
      </div> -->
    </div>
  </mat-card-content>
</mat-card>
