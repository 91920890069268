<qaroni-outside-toolbar></qaroni-outside-toolbar>
<div class="d-flex flex-column justify-content-between auth-wrapper">
  <div class="container-fluid px-0">
    <div class="row no-gutters">
      <div class="col-12">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
  <qaroni-outside-footer></qaroni-outside-footer>
</div>
