<mat-card @enterFadeIn class="card-bg-shop">
  <mat-card-content>
    <div class="row justify-content-end align-items-center">
      <div class="col-auto">
        <a
          class="mat-small qaroni-dark-link"
          [routerLink]="['/dashboard/merchants/list']"
          >{{ "Ver establecimientos" | translate }}</a
        >
      </div>
    </div>
    <div class="row">
      <div class="col-12 px-3 mb-1">
        <div class="mat-small">
          {{ "Tarjeta Bono" | translate }}: {{ card?.status | cardStatus }}
        </div>
      </div>
      <div class="col-12 mb-2">
        <div class="mat-display-1 mb-0 text-center">
          {{ card?.balance | integerToCurrency | currency }}
        </div>
      </div>
      <div class="col-12 mb-3 text-right">
        <div class="mat-small">
          <span>{{ "Vence el" | translate }}: </span>
          <strong>{{ card?.expirationDate | date: "medium" }}</strong>
        </div>
      </div>
      <div class="col-12">
        <a
          mat-flat-button
          color="shop"
          [href]="downloadAppleWallet"
          target="_blank"
          class="text-decoration-none btn-block"
          [disabled]="disabled"
          download
          *ngIf="isActive"
        >
          <mat-icon>phone_iphone</mat-icon>
          {{ "Descargar en Apple Wallet" | translate }}
        </a>
        <!-- <a mat-flat-button color="shop" [href]="downloadAndroid" target="_blank" class="text-decoration-none btn-block"
          [disabled]="disabled" download *ngIf="isActive">
          <mat-icon>android</mat-icon> {{'Descargar en Android'|translate}}
        </a> -->
        <button
          mat-flat-button
          color="shop"
          type="button"
          class="text-decoration-none btn-block"
          [disabled]="disabled"
          (click)="showDownloadAndroidDialog()"
          *ngIf="isActive"
        >
          <mat-icon>android</mat-icon> {{ "Descargar en Android" | translate }}
        </button>
        <a
          mat-flat-button
          color="shop"
          [href]="downloadPdf"
          target="_blank"
          class="text-decoration-none btn-block"
          [disabled]="disabled"
          download
          *ngIf="allowPdf"
        >
          <mat-icon>picture_as_pdf</mat-icon>
          {{ "Descargar en PDF" | translate }}
        </a>
        <a
          mat-button
          color="shop"
          [routerLink]="['/dashboard/cards', card?.cardId, 'transactions']"
          [queryParams]="{ cardType: card?.type }"
          class="text-decoration-none btn-block"
          [disabled]="disabled"
        >
          <mat-icon>list</mat-icon> {{ "Ver operaciones" | translate }}
        </a>
      </div>
      <!-- <div class="col-12">
        <iframe [src]="previewPdf(card)|sanitizeUrl" class="w-100" style="height:500px;" frameborder="0"></iframe>
      </div> -->
    </div>
  </mat-card-content>
</mat-card>
