<button mat-button type="button" class="close-icon" [mat-dialog-close]="false">
  <mat-icon>close</mat-icon>
</button>
<div mat-dialog-title>{{data.title}}</div>
<mat-dialog-content>
  <p [innerHTML]="data.message"></p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <div class="row justify-content-end align-items-center">
    <div class="col-auto">
      <button mat-button color="warn" type="button" class="m-1" (click)="onCancelClick()">
        <mat-icon *ngIf="data?.cancelMatIcon">{{data.cancelMatIcon}}</mat-icon>
        {{data?.cancelText||'Cancelar'|translate}}
      </button>
    </div>
    <div class="col-auto">
      <button mat-flat-button color="accent" type="button" class="m-1" [mat-dialog-close]="true" cdkFocusInitial>
        <mat-icon>{{data.confirmMatIcon}}</mat-icon> {{data.confirmText}}
      </button>
    </div>
  </div>
</mat-dialog-actions>
