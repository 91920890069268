import { Component, OnInit } from '@angular/core';
import { qaroniEnterFadeInTrigger } from '@qaroni-shared/animations';

@Component({
  selector: 'qaroni-alert-applications',
  templateUrl: './alert-applications.component.html',
  styleUrls: ['./alert-applications.component.scss'],
  animations: [qaroniEnterFadeInTrigger],
})
export class AlertApplicationsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
