import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { OAuthStorageService } from '@qaroni-app/application/auth';
import { AllAppService, CommonsHttpService } from '@qaroni-core/services';
import { Observable, Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Card, CreateCardJson } from '../types/card';
import { CardStatusEnum } from '../types/card-status.enum';
import { CardsSnackbars } from '../types/cards-snackbars.config';
import { CardHttpService } from './card-http.service';

@Injectable({
  providedIn: 'root',
})
export class CardService {
  protected readonly cardsSubject = new Subject<Card[]>();
  protected readonly cardSubject = new Subject<Card>();

  constructor(
    private allApp: AllAppService,
    private cardHttp: CardHttpService,
    private commonsHttp: CommonsHttpService,
    public oAuthStorage: OAuthStorageService
  ) {}

  private enableLoading(): void {
    this.allApp.progressBar.show();
  }

  private disableLoading(): void {
    this.allApp.progressBar.hide();
  }

  public isActive(card: Card): boolean {
    return card?.status === CardStatusEnum.ACTIVE;
  }

  public isExpired(card: Card): boolean {
    return card?.status === CardStatusEnum.EXPIRED;
  }

  public isInactive(card: Card): boolean {
    return card?.status === CardStatusEnum.INACTIVE;
  }

  private instant(message: string): any {
    return this.allApp.translate.instant(message);
  }

  // ==========================================================================================
  // Create Cards
  // ==========================================================================================

  public getCards$(): Observable<Card[]> {
    return this.cardsSubject.asObservable();
  }

  public getCards(queryParams?: Params): void {
    if (this.oAuthStorage.hasOAuth && this.oAuthStorage.getClientID) {
      this.enableLoading();
      this.cardHttp
        .getCards$(this.oAuthStorage.getClientID, queryParams)
        .pipe(finalize(() => this.disableLoading()))
        .subscribe({ next: this.nextGetCards, error: this.errorGetCards });
    }
  }

  private nextGetCards = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const cards: Card[] = data.body.result;
      this.cardsSubject.next(cards);
    } else {
      this.cardsSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  }

  private errorGetCards = (error: HttpErrorResponse): void => {
    this.cardsSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  }

  // ==========================================================================================
  // Create Card
  // ==========================================================================================

  public getCard$(): Observable<Card> {
    return this.cardSubject.asObservable();
  }

  public createCard(createCardJSON: CreateCardJson): void {
    if (this.oAuthStorage.hasOAuth && this.oAuthStorage.getClientID) {
      this.enableLoading();
      this.cardHttp
        .createCard$(this.oAuthStorage.getClientID, createCardJSON)
        .pipe(finalize(() => this.disableLoading()))
        .subscribe({ next: this.nextCreateCard, error: this.errorCreateCard });
    }
  }

  private nextCreateCard = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const card: Card = data.body.result[0];
      this.cardSubject.next(card);
    } else {
      this.cardSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  }

  private errorCreateCard = (error: HttpErrorResponse): void => {
    if (
      this.commonsHttp.errorsHttp.isControlledError(error) &&
      this.commonsHttp.errorsHttp.isErrorCode(
        error,
        `E0001`,
        `Could not create more cards.`,
        `Se alcanzó el importe máximo de la campaña`
      )
    ) {
      this.allApp.snackbar.open(
        this.instant(CardsSnackbars.failureCreateCardLimit.message),
        this.instant(CardsSnackbars.failureCreateCardLimit.closeBtn),
        CardsSnackbars.failureCreateCardLimit.config
      );
    }
    this.cardSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  }

  // ==========================================================================================
  // Get Card
  // ==========================================================================================

  public getCard(cardID: number | string): void {
    if (this.oAuthStorage.hasOAuth && this.oAuthStorage.getClientID) {
      this.enableLoading();
      this.cardHttp
        .getCard$(this.oAuthStorage.getClientID, cardID)
        .pipe(finalize(() => this.disableLoading()))
        .subscribe({ next: this.nextGetCard, error: this.errorGetCard });
    }
  }

  private nextGetCard = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const card: Card = data.body.result[0];
      this.cardSubject.next(card);
    } else {
      this.cardSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  }

  private errorGetCard = (error: HttpErrorResponse): void => {
    this.cardSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  }

  // ==========================================================================================
  // Get Download Apple Wallet
  // ==========================================================================================

  public getDownloadAppleWallet(
    cardID: number | string,
    wallet: string
  ): string {
    if (this.oAuthStorage.hasOAuth && this.oAuthStorage.getClientID) {
      const clientID: number = this.oAuthStorage.getClientID;
      return this.cardHttp.buildDownloadAppleWalletUrl(
        clientID,
        cardID,
        wallet
      );
    }
  }

  // ==========================================================================================
  // Get Download Android
  // ==========================================================================================

  public getDownloadAndroid(cardID: number | string, wallet: string): string {
    if (this.oAuthStorage.hasOAuth && this.oAuthStorage.getClientID) {
      const clientID: number = this.oAuthStorage.getClientID;
      return this.cardHttp.buildDownloadAndroidUrl(clientID, cardID, wallet);
    }
  }

  // ==========================================================================================
  // Get Download PDF
  // ==========================================================================================

  public getDownloadPdf(cardID: number | string, wallet: string): string {
    if (this.oAuthStorage.hasOAuth && this.oAuthStorage.getClientID) {
      const clientID: number = this.oAuthStorage.getClientID;
      return this.cardHttp.buildDownloadPdfUrl(clientID, cardID, wallet);
    }
  }

  // ==========================================================================================
  // Get Preview PDF
  // ==========================================================================================

  public getPreviewPdf(cardID: number | string, wallet: string): string {
    if (this.oAuthStorage.hasOAuth && this.oAuthStorage.getClientID) {
      const clientID: number = this.oAuthStorage.getClientID;
      return this.cardHttp.buildPreviewPdfUrl(clientID, cardID, wallet);
    }
  }
}
