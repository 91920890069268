import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CardStatusEnum, CardTypeEnum } from '@qaroni-core/entities';
import { qaroniEnterFadeInTrigger } from '@qaroni-shared/animations';
import * as moment from 'moment';

@Component({
  selector: 'qaroni-card-disabled-preview',
  templateUrl: './card-disabled-preview.component.html',
  styleUrls: ['./card-disabled-preview.component.scss'],
  animations: [qaroniEnterFadeInTrigger],
})
export class CardDisabledPreviewComponent implements OnInit {
  @Input() cardType: CardTypeEnum;
  @Input() disabled = false;

  @Output() create: EventEmitter<CardTypeEnum> = new EventEmitter();

  public expired = moment('2021-08-03').endOf('day').format();

  constructor() {}

  ngOnInit(): void {}

  get cardStatus(): CardStatusEnum {
    return CardStatusEnum.INACTIVE;
  }

  get cardBalance(): number {
    return 5000;
  }

  public createCard(): void {
    this.create.emit(this.cardType);
  }
}
