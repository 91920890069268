<ng-container *ngIf="fc?.errors?.required">
  {{'El campo es obligatorio'|translate}}.
  {{stringHelper||aditionalString?.required}}
</ng-container>

<ng-container *ngIf="fc?.errors?.pattern">
  {{'Utiliza un formato que coincida con el solicitado'|translate}}.
  {{stringHelper||aditionalString?.pattern}}
</ng-container>

<ng-container *ngIf="fc?.errors?.min">
  {{'El valor debe ser superior o igual a'|translate}} {{fc?.errors?.min?.min}}.
  {{stringHelper||aditionalString?.min}}
</ng-container>

<ng-container *ngIf="fc?.errors?.max">
  {{'El valor debe ser inferior o igual a'|translate}} {{fc?.errors?.max?.max}}.
  {{stringHelper||aditionalString?.max}}
</ng-container>

<ng-container *ngIf="fc?.errors?.email">
  {{'Debe ser una dirección de correo electrónico válida'|translate}}.
  {{stringHelper||aditionalString?.email}}
</ng-container>

<ng-container *ngIf="fc?.errors?.minlength">
  {{'La longitud debe ser como mínimo'|translate}} {{fc?.errors?.minlength?.requiredLength}} {{'caracteres'|translate}}.
  {{stringHelper||aditionalString?.minlength}}
</ng-container>

<ng-container *ngIf="fc?.errors?.maxlength">
  {{'La longitud debe ser como máximo'|translate}} {{fc?.errors?.maxlength?.requiredLength}} {{'caracteres'|translate}}.
  {{stringHelper||aditionalString?.maxlength}}
</ng-container>

<ng-container *ngIf="fc?.errors?.matDatepickerMin">
  {{'Fecha no permitida, debe ser posterior'|translate}}.
  {{stringHelper||aditionalString?.matDatepickerMin}}
</ng-container>

<ng-container *ngIf="fc?.errors?.matDatepickerMax">
  {{'Fecha no permitida, debe ser anterior'|translate}}.
  {{stringHelper||aditionalString?.matDatepickerMax}}
</ng-container>

<ng-container *ngIf="fc?.errors?.matchPassword">
  {{'Las contraseñas no coinciden'|translate}}.
</ng-container>

<ng-container *ngIf="fc?.errors?.provinceList">
  {{'La provincia no existe'|translate}}.
</ng-container>

<!-- {{fc?.errors|json}} -->
