import { BreakpointObserver } from '@angular/cdk/layout';
import { Location, ViewportScroller } from '@angular/common';
import { Injectable, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GoogleReCaptchaEnv } from '@qaroni-core/config';
import { BuildHttpData, FilesValidations } from '@qaroni-core/utils';
import { LanguageService } from '../language/language.service';
import { ProgressBarService } from '../progress-bar/progress-bar.service';

@Injectable({
  providedIn: 'root',
})
export class AllAppService {
  public FilesValidations = FilesValidations;
  public BuildHttpData = BuildHttpData;

  constructor(
    public language: LanguageService,
    public snackbar: MatSnackBar,
    public router: Router,
    public viewportScroller: ViewportScroller,
    public location: Location,
    public dialog: MatDialog,
    public breakpointObserver: BreakpointObserver,
    public ngZone: NgZone,
    public progressBar: ProgressBarService,
    public translate: TranslateService
  ) {}

  get hasReCaptcha(): boolean {
    if (GoogleReCaptchaEnv.scriptSrc && GoogleReCaptchaEnv.sitekey) {
      return true;
    }
    return false;
  }
}
