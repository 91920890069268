export enum CountryEnum {
  DEFAULT = 'ES',
  ES = 'ES',
}

export type CountryEnumType = CountryEnum.ES;

export const CountryEnumArray = [CountryEnum.ES];

export const CountryEnumInfo = [
  {
    [CountryEnum.ES]: {
      name: 'España',
    },
  },
];
