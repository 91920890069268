import { Component, Input, OnInit } from '@angular/core';
import { ImagesConfig } from '@qaroni-core/config';

@Component({
  selector: 'qaroni-image-aspect-ratio',
  templateUrl: './image-aspect-ratio.component.html',
  styleUrls: ['./image-aspect-ratio.component.scss'],
})
export class ImageAspectRatioComponent implements OnInit {
  @Input() src: string;
  @Input() aspectRatio: string;

  constructor() {}

  ngOnInit(): void {}

  get getBgImageSrc(): string {
    if (this.src) {
      return `url(${this.src})`;
    }
    return `url(${ImagesConfig.emptyDefault})`;
  }

  public getAspectRatioPercent(): string {
    if (this.aspectRatio === '1:2') {
      return 'aspect-ratio-1x2';
    } else if (this.aspectRatio === '9:16') {
      return 'aspect-ratio-9x16';
    } else if (this.aspectRatio === '2:3') {
      return 'aspect-ratio-2x3';
    } else if (this.aspectRatio === '3:4') {
      return 'aspect-ratio-3x4';
    } else if (this.aspectRatio === '4:5') {
      return 'aspect-ratio-4x5';
    } else if (this.aspectRatio === '1:1') {
      return 'aspect-ratio-1x1';
    } else if (this.aspectRatio === '5:4') {
      return 'aspect-ratio-5x4';
    } else if (this.aspectRatio === '4:3') {
      return 'aspect-ratio-4x3';
    } else if (this.aspectRatio === '3:2') {
      return 'aspect-ratio-3x2';
    } else if (this.aspectRatio === '16:9') {
      return 'aspect-ratio-16x9';
    } else if (this.aspectRatio === '2:1') {
      return 'aspect-ratio-2x1';
    } else if (this.aspectRatio === '4:1') {
      return 'aspect-ratio-4x1';
    } else if (this.aspectRatio === '5:1') {
      return 'aspect-ratio-5x1';
    }
    return null;
  }
}
