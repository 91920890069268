import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'qaroni-outside-init',
  templateUrl: './outside-init.component.html',
  styleUrls: ['./outside-init.component.scss'],
})
export class OutsideInitComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
