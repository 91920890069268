import { Component, Input, OnInit } from '@angular/core';
import { Client } from '@qaroni-app/application/account';

@Component({
  selector: 'qaroni-address-preview',
  templateUrl: './address-preview.component.html',
  styleUrls: ['./address-preview.component.scss'],
})
export class AddressPreviewComponent implements OnInit {
  @Input() client: Client;

  constructor() {}

  ngOnInit(): void {}
}
