<button mat-icon-button class="big-mat-icon-button" [matMenuTriggerFor]="languageMenu"
  matTooltip="{{'Cambiar idioma'|translate}}">
  <mat-icon>language</mat-icon>
</button>

<mat-menu #languageMenu="matMenu">
  <button mat-menu-item *ngIf="showLanguageOptionEs" (click)="changeLanguage(LanguageEnum.es)">
    <span>Castellano</span>
  </button>
  <button mat-menu-item *ngIf="showLanguageOptionGl" (click)="changeLanguage(LanguageEnum.gl)">
    <span>Galego</span>
  </button>
</mat-menu>
