<div class="row">
  <div class="col-12 mb-3">
    <mat-card @enterFadeIn class="bg-grey">
      <mat-card-content>
        <div class="row">
          <div class="col-auto">
            <ng-content></ng-content>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
