import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { OAuthStorageService } from '@qaroni-app/application/auth';
import { AllAppService, CommonsHttpService } from '@qaroni-core/services';
import { PaginationLinks } from '@qaroni-core/types';
import { Observable, Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Transaction } from '../types/transaction';
import { TransactionResultEnum } from '../types/transaction-result.enum';
import { TransactionTypeEnum } from '../types/transaction-type.enum';
import { TransactionHttpService } from './transaction-http.service';

@Injectable({
  providedIn: 'root',
})
export class TransactionService {
  protected readonly transactionsSubject = new Subject<Transaction[]>();
  protected readonly paginationLinksSubject = new Subject<PaginationLinks>();

  constructor(
    private allApp: AllAppService,
    private transactionHttp: TransactionHttpService,
    private commonsHttp: CommonsHttpService,
    public oAuthStorage: OAuthStorageService
  ) {}

  private enableLoading(): void {
    this.allApp.progressBar.show();
  }

  private disableLoading(): void {
    this.allApp.progressBar.hide();
  }

  public isAdd(transaction: Transaction): boolean {
    return transaction?.type === TransactionTypeEnum.ADD;
  }

  public isSale(transaction: Transaction): boolean {
    return transaction?.type === TransactionTypeEnum.SALE;
  }

  public isAccepted(transaction: Transaction): boolean {
    return transaction?.result === TransactionResultEnum.ACCEPTED;
  }

  public isCancelled(transaction: Transaction): boolean {
    return transaction?.result === TransactionResultEnum.CANCELLED;
  }

  public isDenied(transaction: Transaction): boolean {
    return transaction?.result === TransactionResultEnum.DENIED;
  }

  // ==========================================================================================
  // Get Transactions
  // ==========================================================================================

  public getTransactions$(): Observable<Transaction[]> {
    return this.transactionsSubject.asObservable();
  }

  public resetTransactions(): void {
    return this.transactionsSubject.next(null);
  }

  public getTransactions(cardID: number | string, params?: Params): void {
    if (this.oAuthStorage.hasOAuth && this.oAuthStorage.getClientID) {
      this.enableLoading();
      this.transactionHttp
        .getTransactions$(this.oAuthStorage.getClientID, cardID, params)
        .pipe(finalize(() => this.disableLoading()))
        .subscribe({
          next: this.nextGetTransactions,
          error: this.errorGetTransactions,
        });
    }
  }

  private nextGetTransactions = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const transactions: Transaction[] = data.body.result;
      this.transactionsSubject.next(transactions);
      const paginationLinks: PaginationLinks = data.body.links;
      this.paginationLinksSubject.next(paginationLinks);
    } else {
      this.transactionsSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  }

  private errorGetTransactions = (error: HttpErrorResponse): void => {
    this.transactionsSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  }

  // ==========================================================================================
  // Pagination Links
  // ==========================================================================================

  public getPaginationLinks$(): Observable<PaginationLinks> {
    return this.paginationLinksSubject.asObservable();
  }
}
