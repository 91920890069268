export enum DocumentTypeEnum {
  DNI = 'DNI',
  NIE = 'NIE',
  CIF = 'CIF',
  PASSPORT = 'PASSPORT',
}

export type DocumentType =
  | DocumentTypeEnum.DNI
  | DocumentTypeEnum.NIE
  | DocumentTypeEnum.CIF
  | DocumentTypeEnum.PASSPORT;

export const DocumentTypeArray = [
  DocumentTypeEnum.DNI,
  DocumentTypeEnum.NIE,
  DocumentTypeEnum.CIF,
  DocumentTypeEnum.PASSPORT,
];

export const DocumentTypeInfo = [
  {
    [DocumentTypeEnum.DNI]: {
      name: 'DNI',
    },
  },
  {
    [DocumentTypeEnum.NIE]: {
      name: 'NIE',
    },
  },
  {
    [DocumentTypeEnum.CIF]: {
      name: 'CIF',
    },
  },
  {
    [DocumentTypeEnum.PASSPORT]: {
      name: 'Pasaporte',
    },
  },
];
