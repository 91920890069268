import { Component, Input, OnInit } from '@angular/core';
import { Merchant } from '@qaroni-app/application/merchants';

@Component({
  selector: 'qaroni-address-string',
  templateUrl: './address-string.component.html',
  styleUrls: ['./address-string.component.scss'],
})
export class AddressStringComponent implements OnInit {
  @Input() merchant: Merchant;

  @Input() map = true;

  constructor() {}

  ngOnInit(): void {}

  get showMapIcon(): boolean {
    return this.map && this.merchant?.latitude && this.merchant?.longitude
      ? true
      : false;
  }

  get googleMapsUrl(): string {
    if (this.showMapIcon) {
      return `https://www.google.com/maps/search/?api=1&query=${this.merchant?.latitude},${this.merchant?.longitude}`;
    }
  }
}
