import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'integerToCurrency'
})
export class IntegerToCurrencyPipe implements PipeTransform {
  transform(value: number): string {
    return (value / 100).toFixed(2);
  }
}
