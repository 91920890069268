import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '@qaroni-shared/material';
import { PipesModule } from '@qaroni-shared/pipes';
import { AddressPreviewComponent } from './address-preview/address-preview.component';
import { AddressStringComponent } from './address-string/address-string.component';
import { ButtonsBarComponent } from './buttons-bar/buttons-bar.component';
import { CardDisabledPreviewComponent } from './card-disabled-preview/card-disabled-preview.component';
import { CardPreviewComponent } from './card-preview/card-preview.component';
import { ChangeLanguageComponent } from './change-language/change-language.component';
import { ConfirmationDialogComponent } from './dialogs/confirmation-dialog/confirmation-dialog.component';
import { ErrorsHttpDialogComponent } from './dialogs/errors-http-dialog/errors-http-dialog.component';
import { GrecaptchaComponent } from './grecaptcha/grecaptcha.component';
import { HelpSectionComponent } from './help-section/help-section.component';
import { ImageAspectRatioComponent } from './image-aspect-ratio/image-aspect-ratio.component';
import { InputErrorMessagesComponent } from './input-error-messages/input-error-messages.component';
import { OutsideFooterComponent } from './layout/outside-footer/outside-footer.component';
import { OutsideInitComponent } from './layout/outside-init/outside-init.component';
import { OutsideToolbarComponent } from './layout/outside-toolbar/outside-toolbar.component';
import { MerchantPreviewComponent } from './merchant-preview/merchant-preview.component';
import { PageTitleComponent } from './page-title/page-title.component';
import { ProgressSpinnerComponent } from './progress-spinner/progress-spinner.component';
import { RequiredIndicatorComponent } from './required-indicator/required-indicator.component';
import { TransactionsTableComponent } from './transactions-table/transactions-table.component';
import { DownloadAndroidDialogComponent } from './dialogs/download-android-dialog/download-android-dialog.component';
import { AlertApplicationsComponent } from './alert-applications/alert-applications.component';

@NgModule({
  declarations: [
    AddressPreviewComponent,
    AddressStringComponent,
    AlertApplicationsComponent,
    ButtonsBarComponent,
    CardDisabledPreviewComponent,
    CardPreviewComponent,
    ChangeLanguageComponent,
    ConfirmationDialogComponent,
    DownloadAndroidDialogComponent,
    ErrorsHttpDialogComponent,
    GrecaptchaComponent,
    HelpSectionComponent,
    ImageAspectRatioComponent,
    InputErrorMessagesComponent,
    MerchantPreviewComponent,
    OutsideFooterComponent,
    OutsideInitComponent,
    OutsideToolbarComponent,
    PageTitleComponent,
    ProgressSpinnerComponent,
    RequiredIndicatorComponent,
    TransactionsTableComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    PipesModule,
    RouterModule,
    TranslateModule,
  ],
  exports: [
    AddressPreviewComponent,
    AddressStringComponent,
    AlertApplicationsComponent,
    ButtonsBarComponent,
    CardDisabledPreviewComponent,
    CardPreviewComponent,
    ChangeLanguageComponent,
    GrecaptchaComponent,
    HelpSectionComponent,
    ImageAspectRatioComponent,
    InputErrorMessagesComponent,
    MerchantPreviewComponent,
    OutsideFooterComponent,
    OutsideInitComponent,
    OutsideToolbarComponent,
    PageTitleComponent,
    ProgressSpinnerComponent,
    RequiredIndicatorComponent,
    TransactionsTableComponent,
  ],
})
export class ComponentsModule {}
