import { Component, OnInit } from '@angular/core';
import { AllAppService } from '@qaroni-core/services';
import { LanguageEnum } from '@qaroni-core/types';

@Component({
  selector: 'qaroni-change-language',
  templateUrl: './change-language.component.html',
  styleUrls: ['./change-language.component.scss'],
})
export class ChangeLanguageComponent implements OnInit {
  public LanguageEnum = LanguageEnum;

  constructor(private allApp: AllAppService) {}

  ngOnInit(): void {}

  public changeLanguage(language: LanguageEnum): void {
    this.allApp.language.setLanguage(language);
  }

  get showLanguageOptionEs(): boolean {
    return this.allApp.language.showLanguageOptionEs;
  }

  get showLanguageOptionGl(): boolean {
    return this.allApp.language.showLanguageOptionGl;
  }
}
