import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from './components';
import { MaterialModule } from './material';
import { NgBootstrapModule } from './ng-bootstrap';
import { PipesModule } from './pipes';

@NgModule({
  declarations: [],
  imports: [CommonModule, MaterialModule, NgBootstrapModule, ComponentsModule],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    NgBootstrapModule,
    ComponentsModule,
    PipesModule,
    TranslateModule,
  ],
})
export class SharedModule {}
