<p class="position-relative">
  <mat-toolbar color="primary">
    <button
      mat-icon-button
      [matMenuTriggerFor]="burguerMenu"
      aria-label="Menú"
      [matTooltip]="'Menú' | translate"
    >
      <mat-icon>menu</mat-icon>
    </button>

    <div class="wrapper-logo ml-3">
      <a [routerLink]="['/']">
        <img
          [src]="ImagesConfig.toolbar"
          [alt]="AppConfig.name"
          class="img-fluid img-caceres"
        />
      </a>
    </div>
    <!-- <div class="wrapper-logo mx-3">
      <a [routerLink]="['/']">
        <img
          [src]="ImagesConfig.toolbarXacobeo"
          [alt]="AppConfig.name"
          class="img-fluid img-xacobeo"
        />
      </a>
    </div> -->

    <!-- <a [routerLink]="['/']" class="text-reset text-decoration-none">{{AppConfig.name}}</a> -->

    <span class="qaroni-toolbar-spacer"></span>

    <ng-container *ngIf="!(isSmall$ | async)">
      <a
        [routerLink]="['/auth/login']"
        class="text-reset text-decoration-none mr-3"
        >{{ "Iniciar sesión" | translate }}</a
      >
      <a
        [routerLink]="['/merchants/list']"
        class="text-reset text-decoration-none mr-2"
        >{{ "Ver establecimientos" | translate }}</a
      >
    </ng-container>

    <!-- <qaroni-change-language></qaroni-change-language> -->
  </mat-toolbar>
  <mat-progress-bar
    *ngIf="progressBar$ | async"
    color="primary"
    mode="indeterminate"
  ></mat-progress-bar>
</p>

<mat-menu #burguerMenu="matMenu">
  <button mat-menu-item [routerLink]="['/auth/login']">
    <mat-icon>account_circle</mat-icon> {{ "Iniciar sesión" | translate }}
  </button>
  <mat-divider></mat-divider>
  <button mat-menu-item [routerLink]="['/merchants/list']">
    <mat-icon>store</mat-icon> {{ "Lista de establecimientos" | translate }}
  </button>
  <button mat-menu-item [routerLink]="['/merchants/map']">
    <mat-icon>map</mat-icon> {{ "Establecimientos en el mapa" | translate }}
  </button>
</mat-menu>
