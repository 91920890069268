import { Component, OnInit } from '@angular/core';
import { qaroniEnterFadeInTrigger } from '@qaroni-shared/animations';

@Component({
  selector: 'qaroni-buttons-bar',
  templateUrl: './buttons-bar.component.html',
  styleUrls: ['./buttons-bar.component.scss'],
  animations: [qaroniEnterFadeInTrigger],
})
export class ButtonsBarComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
