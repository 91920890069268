import { Component, OnInit } from '@angular/core';
import { AppUrlsConfig } from '@qaroni-core/config';

@Component({
  selector: 'qaroni-help-section',
  templateUrl: './help-section.component.html',
  styleUrls: ['./help-section.component.scss'],
})
export class HelpSectionComponent implements OnInit {
  AppUrlsConfig = AppUrlsConfig;

  constructor() {}

  ngOnInit(): void {}
}
