import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageEnum, LanguageEnumArray } from '@qaroni-core/types';
import { Observable, Subject } from 'rxjs';
import { AppStorageService } from '../app-storage/app-storage.service';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  protected readonly languageSubject = new Subject<LanguageEnum>();

  constructor(
    private translate: TranslateService,
    private appStorage: AppStorageService
  ) {
    translate.addLangs(LanguageEnumArray);
    translate.setDefaultLang(LanguageEnum.default);
    this.initializeLanguage();
  }

  public getLanguage$(): Observable<LanguageEnum> {
    return this.languageSubject.asObservable();
  }

  public setLanguage(language: LanguageEnum): void {
    this.appStorage.setLanguage(language);
    this.translate.use(language.toLowerCase());
    this.languageSubject.next(language);
  }

  private initializeLanguage(): void {
    if (this.appStorage.hasLanguage) {
      this.setLanguage(this.appStorage.getLanguage());
    } else {
      this.setLanguage(LanguageEnum.default);
      // if (this.hasNavigatorLanguage) {
      //   this.setLanguage(this.getNavigatorLanguage);
      // } else {
      //   this.setLanguage(LanguageEnum.default);
      // }
    }
  }

  get hasNavigatorLanguage(): boolean {
    for (const navigatorLanguage of navigator.languages) {
      if (
        LanguageEnumArray.includes(navigatorLanguage as LanguageEnum) === true
      ) {
        return true;
      }
    }
    return false;
  }

  get getNavigatorLanguage(): LanguageEnum {
    for (const navigatorLanguage of navigator.languages) {
      if (
        LanguageEnumArray.includes(navigatorLanguage as LanguageEnum) === true
      ) {
        return navigatorLanguage as LanguageEnum;
      }
    }
    return null;
  }

  // ==========================================================================================
  // Languages Buttons Validations
  // ==========================================================================================

  get showLanguageOptionEs(): boolean {
    if (this.appStorage.getLanguage() !== LanguageEnum.es) {
      return true;
    }
    return false;
  }

  get showLanguageOptionGl(): boolean {
    if (this.appStorage.getLanguage() !== LanguageEnum.gl) {
      return true;
    }
    return false;
  }
}
