import { Component, OnInit } from '@angular/core';
import { AppConfig, ImagesConfig } from '@qaroni-core/config';

@Component({
  selector: 'qaroni-progress-spinner',
  templateUrl: './progress-spinner.component.html',
  styleUrls: ['./progress-spinner.component.scss'],
})
export class ProgressSpinnerComponent implements OnInit {
  public AppConfig = AppConfig;
  public ImagesConfig = ImagesConfig;

  constructor() {}

  ngOnInit(): void {}
}
