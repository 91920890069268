<div class="row justify-content-center align-items-center">
  <div class="col-auto col-image">
    <img [src]="ImagesConfig.spinner" [alt]="AppConfig.name" class="img-fluid">
  </div>
  <div class="col-auto col-spinner">
    <div class="wrapper-spinner">
      <mat-spinner color="accent"></mat-spinner>
    </div>
  </div>
</div>
