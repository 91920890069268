export enum TransactionTypeEnum {
  ADD = 'ADD',
  SALE = 'SALE',
}

export type TransactionType =
  | TransactionTypeEnum.ADD
  | TransactionTypeEnum.SALE;

export const TransactionTypeArray = [
  TransactionTypeEnum.ADD,
  TransactionTypeEnum.SALE,
];

export const TransactionTypeInfo = [
  {
    [TransactionTypeEnum.ADD]: {
      name: 'Liquidación',
    },
  },
  {
    [TransactionTypeEnum.SALE]: {
      name: 'Venta',
    },
  },
];
