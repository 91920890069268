export enum CardStatusEnum {
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
  INACTIVE = 'INACTIVE',
}

export type CardStatusType =
  | CardStatusEnum.ACTIVE
  | CardStatusEnum.EXPIRED
  | CardStatusEnum.INACTIVE;

export const CardStatusArray = [
  CardStatusEnum.ACTIVE,
  CardStatusEnum.EXPIRED,
  CardStatusEnum.INACTIVE,
];

export const CardStatusInfo = [
  {
    [CardStatusEnum.ACTIVE]: {
      name: 'Activa',
    },
  },
  {
    [CardStatusEnum.EXPIRED]: {
      name: 'Expirada',
    },
  },
  {
    [CardStatusEnum.INACTIVE]: {
      name: 'Inactiva',
    },
  },
];
