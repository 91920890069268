import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiQBonoEnv } from '@qaroni-core/config';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AddressHttpService {
  constructor(private http: HttpClient) {}

  public getProvinces$(): Observable<HttpResponse<any>> {
    const url = `${ApiQBonoEnv.baseUrl}/provinces`;

    return this.http.get(url, { observe: 'response' });
  }

  public getCouncils$(
    provinceID: number | string
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQBonoEnv.baseUrl}/provinces/${provinceID}/cities`;

    return this.http.get(url, { observe: 'response' });
  }
}
