import { Pipe, PipeTransform } from '@angular/core';
import {
  TransactionResultEnum,
  TransactionResultInfo
} from '@qaroni-app/application/transactions';

@Pipe({
  name: 'transactionResult',
})
export class TransactionResultPipe implements PipeTransform {
  transform(value: TransactionResultEnum): string {
    if (value) {
      const key = value.trim().toUpperCase();
      for (const iterator of TransactionResultInfo) {
        if (Object.keys(iterator)[0] === key) {
          return iterator[key].name;
        }
      }
    }
    return null;
  }
}
