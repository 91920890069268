export const ImagesConfig = {
  toolbar: `https://s3.eu-central-1.amazonaws.com/bonos.caceres/logos/escudo-navalmoral-oficial.png`,
  footerOwner: `https://s3.eu-central-1.amazonaws.com/bonos.caceres/logos/escudo-navalmoral-oficial.png`,
  footerOwnerAlt: `Ayuntamiento de Navalmoral de la Mata`,
  footerPartner: `https://s3.eu-central-1.amazonaws.com/bonos.caceres/logos/abanca-blanco.png`,
  footerPartnerAlt: `Abanca`,
  spinner: `https://s3.eu-central-1.amazonaws.com/bonos.caceres/logos/escudo-caceres-full-colors.png`,
  emptyDefault: `https://s3.eu-central-1.amazonaws.com/bonos.caceres/images/empty-image-default.png`,
  initImage: `https://s3.eu-central-1.amazonaws.com/bonos.caceres/images/bonos-caceres-preview.png`,
  backgroundSupport: `https://s3.eu-central-1.amazonaws.com/bonos.caceres/images/backgroud_support.svg`,
  backgroundSupportAlt: `Soporte`,

  walletPassesLogo: `https://s3.eu-central-1.amazonaws.com/bonos.caceres/images/wallet-passes-logo.png`,
  walletPassesLogoAlt: `Wallet Passes`,

  googlePlayStoreIcon: `https://s3.eu-central-1.amazonaws.com/bonos.caceres/images/playStore.png`,
  googlePlayStoreIconAlt: `Google Play Store`,

  passTwoULogo: `https://s3.eu-central-1.amazonaws.com/bonos.caceres/images/pass-2u-logo.png`,
  passTwoULogoAlt: `Pass 2U`,

  postSignUp: `https://s3.eu-central-1.amazonaws.com/bonos.caceres/images/bonos-caceres-preview.png`,
  postSignUpAlt: `Post Sign Up`,

  footerCamaraCaceres: `https://s3.eu-central-1.amazonaws.com/bonos.caceres/logos/camara-caceres-white.png`,
  footerCamaraCaceresAlt: `Cámara de Comercio de Cáceres`,

  footerQaroni: `https://s3.eu-central-1.amazonaws.com/bonos.caceres/logos/logo_qaroni_white.png`,
  footerQaroniAlt: `Qaroní`,
};
