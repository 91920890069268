export type CurrencyEnum = 'EUR';

export type CurrencyEnumType = 'EUR';

export const CurrencyEnumArray = ['EUR'];

export const CurrencyEnumInfo = [
  {
    eur: {
      name: 'Euros',
    },
  },
];
