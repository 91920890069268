import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { GoogleReCaptchaEnv } from '@qaroni-core/config';
import { AllAppService } from '@qaroni-core/services';
import { ReCaptchaConfig } from '@qaroni-core/types';

declare const grecaptcha: any;

declare global {
  interface Window {
    grecaptcha: any;
    gReCaptchaOnLoadCallback: () => void;
  }
}

@Component({
  selector: 'qaroni-grecaptcha',
  templateUrl: './grecaptcha.component.html',
  styleUrls: ['./grecaptcha.component.scss'],
})
export class GrecaptchaComponent implements OnInit, OnDestroy {
  @Input() config: ReCaptchaConfig = {};

  @Output() captchaResponse: EventEmitter<string> = new EventEmitter();
  @Output() captchaExpired: EventEmitter<null> = new EventEmitter();

  @ViewChild('recaptcha', { static: true }) recaptchaElement: ElementRef;

  private widgetId: number;
  private tagScriptId = 'recaptcha-js-sdk';

  constructor(private allApp: AllAppService) {}

  ngOnInit(): void {
    if (this.hasReCaptcha) {
      this.registerReCaptchaCallback();
      this.addReCaptchaScript();
    }
  }

  ngOnDestroy(): void {
    const script = document.getElementById(this.tagScriptId);
    if (script) {
      script.parentNode.removeChild(script);
    }
  }

  get hasReCaptcha(): boolean {
    return this.allApp.hasReCaptcha;
  }

  private registerReCaptchaCallback(): void {
    window.gReCaptchaOnLoadCallback = () => {
      const config = {
        ...this.config,
        sitekey: GoogleReCaptchaEnv.sitekey,
        callback: this.onSuccess.bind(this),
        'expired-callback': this.onExpired.bind(this),
      };
      this.widgetId = this.renderReCaptcha(
        this.recaptchaElement.nativeElement,
        config
      );
    };
  }

  private renderReCaptcha(element: HTMLElement, config): number {
    return window.grecaptcha.render(element, config);
  }

  private addReCaptchaScript(): void {
    if (document.getElementById(this.tagScriptId)) {
      this.registerReCaptchaCallback();
      return;
    }
    const script = document.createElement('script');
    script.id = this.tagScriptId;
    script.src = GoogleReCaptchaEnv.scriptSrc;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }

  public onSuccess(token: string): void {
    this.allApp.ngZone.run(() => {
      this.captchaResponse.emit(token);
    });
  }

  public onExpired(): void {
    this.allApp.ngZone.run(() => {
      this.captchaExpired.emit();
    });
  }
}
