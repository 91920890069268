<div class="mat-h2 my-5 text-center font-weight-light" *ngIf="paginator?.length === 0">
  {{'No tienes operaciones que mostrar'|translate}}
</div>

<div class="table-responsive" [ngClass]="{ 'd-none': paginator?.length === 0 }">
  <table mat-table matSort class="full-width-table" aria-label="Transactions">
    <!-- CreationDate Column -->
    <ng-container matColumnDef="creationDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Fecha'|translate}}</th>
      <td mat-cell *matCellDef="let row">{{row.creationDate|date:'medium'}}</td>
    </ng-container>

    <!-- Code Column -->
    <ng-container matColumnDef="code">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Código'|translate}}</th>
      <td mat-cell *matCellDef="let row">{{row.code}}</td>
    </ng-container>

    <!-- Full Amount Column -->
    <ng-container matColumnDef="fullAmount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Total venta'|translate}}</th>
      <td mat-cell *matCellDef="let row">{{row.fullAmount|integerToCurrency|currency}}</td>
    </ng-container>

    <!-- Amount Column -->
    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Descuento'|translate}}</th>
      <td mat-cell *matCellDef="let row">{{row.amount|integerToCurrency|currency}}</td>
    </ng-container>

    <!-- Type Column -->
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Tipo'|translate}}</th>
      <td mat-cell *matCellDef="let row">{{row.type|transactionType}}</td>
    </ng-container>

    <!-- Result Column -->
    <ng-container matColumnDef="result">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Resultado'|translate}}</th>
      <td mat-cell *matCellDef="let row">{{row.result|transactionResult}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>

<div [ngClass]="{ 'd-none': (paginator?.length === 0) || !showPaginator }">
  <mat-paginator #paginator [pageSizeOptions]="[25, 50, 100, 250]">
  </mat-paginator>
</div>
