export enum GenderEnum {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
}

export type GenderType = GenderEnum.FEMALE | GenderEnum.MALE;

export const GenderArray = [GenderEnum.FEMALE, GenderEnum.MALE];

export const GenderInfo = [
  {
    [GenderEnum.FEMALE]: {
      name: 'Mujer',
    },
  },
  {
    [GenderEnum.MALE]: {
      name: 'Hombre',
    },
  },
];
