import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree
} from '@angular/router';
import { OAuthService } from '@qaroni-app/application/auth';
import { AllAppService } from '@qaroni-core/services';
import { Observable } from 'rxjs';
import { GuardOAuthSnackbars } from '../snackbars/guard-o-auth-snackbars.config';

@Injectable({
  providedIn: 'root',
})
export class NotOAuthGuard implements CanActivate, CanLoad {
  private redirectTo: string[] = ['/dashboard'];

  constructor(
    private allApp: AllAppService,
    private oAuthService: OAuthService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (!this.oAuthService.hasOAuth) {
      // console.log('NotOAuthGuard -> canActivate -> return true');
      return true;
    }
    this.allApp.snackbar.open(
      GuardOAuthSnackbars.failNotOAuth.message,
      GuardOAuthSnackbars.failNotOAuth.closeBtn,
      GuardOAuthSnackbars.failNotOAuth.config
    );
    // console.log('NotOAuthGuard -> canActivate -> return false');
    this.allApp.router.navigate(this.redirectTo);
    return false;
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.oAuthService.hasOAuth) {
      // console.log('NotOAuthGuard -> canLoad -> return true');
      return true;
    }
    this.allApp.snackbar.open(
      GuardOAuthSnackbars.failNotOAuth.message,
      GuardOAuthSnackbars.failNotOAuth.closeBtn,
      GuardOAuthSnackbars.failNotOAuth.config
    );
    // console.log('NotOAuthGuard -> canLoad -> return false');
    this.allApp.router.navigate(this.redirectTo);
    return false;
  }
}
