import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AllAppService, CommonsHttpService } from '@qaroni-core/services';
import { Observable, Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import {
  ChangePasswordJson,
  ForgotJson,
  LoginJson,
  OtpJson,
  OtpUsernameJson,
  ResetPasswordJson,
  SignUpJson,
  UserDataRegisterJson
} from '../types/authentication';
import { OAuth } from '../types/o-auth';
import { OAuthSnackbars } from '../types/o-auth-snackbars.config';
import { User } from '../types/user';
import { OAuthHttpService } from './o-auth-http.service';
import { OAuthStorageService } from './o-auth-storage.service';

@Injectable({
  providedIn: 'root',
})
export class OAuthService {
  protected readonly oAuthSubject = new Subject<OAuth>();
  protected readonly userSubject = new Subject<User>();
  protected readonly messageSubject = new Subject<string>();

  constructor(
    private allApp: AllAppService,
    private oAuthHttp: OAuthHttpService,
    private commonsHttp: CommonsHttpService,
    public oAuthStorage: OAuthStorageService
  ) {}

  private enableLoading(): void {
    this.allApp.progressBar.show();
  }

  private disableLoading(): void {
    this.allApp.progressBar.hide();
  }

  get hasOAuth(): boolean {
    return this.oAuthStorage.hasOAuth;
  }

  get getUserID(): number {
    return this.oAuthStorage.getUserID;
  }

  get getClientID(): number {
    return this.oAuthStorage.getClientID;
  }

  get getMerchantID(): number {
    return this.oAuthStorage.getMerchantID;
  }

  // ==========================================================================================
  // Message
  // ==========================================================================================

  public getMessage$(): Observable<string> {
    return this.messageSubject.asObservable();
  }

  // ==========================================================================================
  // Register User
  // ==========================================================================================

  public getUser$(): Observable<User> {
    return this.userSubject.asObservable();
  }

  public register(signUpJSON: SignUpJson): void {
    this.enableLoading();
    this.oAuthHttp
      .register$(signUpJSON)
      .pipe(finalize(() => this.disableLoading()))
      .subscribe({ next: this.nextRegister, error: this.errorRegister });
  }

  private nextRegister = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus201(data)) {
      const user: User = data.body.result[0];
      this.userSubject.next(user);
      this.allApp.snackbar.open(
        OAuthSnackbars.successRegister.message,
        OAuthSnackbars.successRegister.closeBtn,
        OAuthSnackbars.successRegister.config
      );
    } else {
      this.userSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  }

  private errorRegister = (error: HttpErrorResponse): void => {
    this.userSubject.next(null);
    if (
      this.commonsHttp.errorsHttp.isControlledError(error) &&
      this.commonsHttp.errorsHttp.isErrorCode(
        error,
        'E0004',
        'A required field is missing.',
        'The email has already been taken.'
      )
    ) {
      this.allApp.snackbar.open(
        OAuthSnackbars.failureRegisterEmailExists.message,
        OAuthSnackbars.failureRegisterEmailExists.closeBtn,
        OAuthSnackbars.failureRegisterEmailExists.config
      );
    } else if (
      this.commonsHttp.errorsHttp.isControlledError(error) &&
      this.commonsHttp.errorsHttp.isErrorCode(
        error,
        'E0004',
        'A required field is missing.',
        'The phone has already been taken.'
      )
    ) {
      this.allApp.snackbar.open(
        OAuthSnackbars.failureRegisterPhoneExists.message,
        OAuthSnackbars.failureRegisterPhoneExists.closeBtn,
        OAuthSnackbars.failureRegisterPhoneExists.config
      );
    } else if (
      this.commonsHttp.errorsHttp.isControlledError(error) &&
      this.commonsHttp.errorsHttp.isErrorCode(
        error,
        'E0004',
        'A required field is missing.',
        'The nif has already been taken.'
      )
    ) {
      this.allApp.snackbar.open(
        OAuthSnackbars.failureRegisterNIFExists.message,
        OAuthSnackbars.failureRegisterNIFExists.closeBtn,
        OAuthSnackbars.failureRegisterNIFExists.config
      );
    } else if (
      this.commonsHttp.errorsHttp.isControlledError(error) &&
      this.commonsHttp.errorsHttp.isErrorCode(
        error,
        'E0004',
        'A required field is missing.',
        ''
      )
    ) {
      this.allApp.snackbar.open(
        OAuthSnackbars.failureRegisterExists.message,
        OAuthSnackbars.failureRegisterExists.closeBtn,
        OAuthSnackbars.failureRegisterExists.config
      );
    }
    this.commonsHttp.errorsHttp.communication(error);
  }

  // ==========================================================================================
  // Validate User
  // ==========================================================================================

  public validate(userID: number | string, otpJSON: OtpJson): void {
    this.enableLoading();
    this.oAuthHttp
      .validate$(userID, otpJSON)
      .pipe(finalize(() => this.disableLoading()))
      .subscribe({ next: this.nextValidate, error: this.errorValidate });
  }

  private nextValidate = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const oAuth: OAuth = data.body.result[0];
      this.oAuthStorage.set(oAuth);
      this.oAuthSubject.next(oAuth);
      this.allApp.snackbar.open(
        this.allApp.translate.instant(OAuthSnackbars.successValidate.message),
        OAuthSnackbars.successValidate.closeBtn,
        OAuthSnackbars.successValidate.config
      );
    } else {
      this.oAuthSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  }

  private errorValidate = (error: HttpErrorResponse): void => {
    this.oAuthSubject.next(null);
    if (
      this.commonsHttp.errorsHttp.isControlledError(error) &&
      this.commonsHttp.errorsHttp.isErrorCode(
        error,
        'E0008',
        `You don't have access permission to see this information.`,
        'Tu rol es diferente a la plataforma de donde estas accediendo'
      )
    ) {
      this.allApp.snackbar.open(
        OAuthSnackbars.failureForgotRole.message,
        OAuthSnackbars.failureForgotRole.closeBtn,
        OAuthSnackbars.failureForgotRole.config
      );
    } else if (
      this.commonsHttp.errorsHttp.isControlledError(error) &&
      this.commonsHttp.errorsHttp.isErrorCode(
        error,
        'E0010',
        'Invalid information in the request.',
        ''
      )
    ) {
      this.allApp.snackbar.open(
        OAuthSnackbars.failureValidate.message,
        OAuthSnackbars.failureValidate.closeBtn,
        OAuthSnackbars.failureValidate.config
      );
    }
    this.commonsHttp.errorsHttp.communication(error);
  }

  // ==========================================================================================
  // Login
  // ==========================================================================================

  public getOAuth$(): Observable<OAuth> {
    return this.oAuthSubject.asObservable();
  }

  public login(loginJSON: LoginJson): void {
    this.enableLoading();
    this.oAuthHttp
      .login$(loginJSON)
      .pipe(finalize(() => this.disableLoading()))
      .subscribe({ next: this.nextLogin, error: this.errorLogin });
  }

  private nextLogin = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const oAuth: OAuth = data.body.result[0];
      this.oAuthStorage.set(oAuth);
      this.oAuthSubject.next(oAuth);
    } else {
      this.oAuthSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  }

  private errorLogin = (error: HttpErrorResponse): void => {
    this.oAuthSubject.next(null);
    if (
      this.commonsHttp.errorsHttp.isControlledError(error) &&
      this.commonsHttp.errorsHttp.isErrorCode(
        error,
        'E0012',
        'Invalid credentials.',
        ''
      )
    ) {
      this.allApp.snackbar.open(
        this.allApp.translate.instant(OAuthSnackbars.failureLogin.message),
        OAuthSnackbars.failureLogin.closeBtn,
        OAuthSnackbars.failureLogin.config
      );
    } else if (
      this.commonsHttp.errorsHttp.isControlledError(error) &&
      this.commonsHttp.errorsHttp.isErrorCode(
        error,
        'E0013',
        'Resource is CANCELLED.',
        'Cuenta cancelada. No se puede hacer el ingreso'
      )
    ) {
      this.allApp.snackbar.open(
        this.allApp.translate.instant(
          OAuthSnackbars.failureLoginCancelled.message
        ),
        OAuthSnackbars.failureLoginCancelled.closeBtn,
        OAuthSnackbars.failureLoginCancelled.config
      );
    } else if (
      this.commonsHttp.errorsHttp.isControlledError(error) &&
      this.commonsHttp.errorsHttp.isErrorCode(
        error,
        'E0014',
        'Resource is INACTIVE.',
        'Cuenta inactiva. No se puede hacer el ingreso'
      )
    ) {
      this.allApp.snackbar.open(
        this.allApp.translate.instant(
          OAuthSnackbars.failureLoginInactive.message
        ),
        OAuthSnackbars.failureLoginInactive.closeBtn,
        OAuthSnackbars.failureLoginInactive.config
      );
    } else if (
      this.commonsHttp.errorsHttp.isControlledError(error) &&
      this.commonsHttp.errorsHttp.isErrorCode(
        error,
        'E0020',
        'Resource is CREATED.',
        'Cuenta no validada. No se puede hacer el ingreso'
      )
    ) {
      this.messageSubject.next(error?.error?.errors[0]?.code);
    }
    this.commonsHttp.errorsHttp.communication(error);
  }

  // ==========================================================================================
  // Forgot Password
  // ==========================================================================================

  public forgot(forgotJSON: ForgotJson): void {
    this.enableLoading();
    this.oAuthHttp
      .forgot$(forgotJSON)
      .pipe(finalize(() => this.disableLoading()))
      .subscribe({ next: this.nextForgot, error: this.errorForgot });
  }

  private nextForgot = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const user: User = data.body.result[0];
      this.userSubject.next(user);
      this.allApp.snackbar.open(
        this.allApp.translate.instant(OAuthSnackbars.successForgot.message),
        OAuthSnackbars.successForgot.closeBtn,
        OAuthSnackbars.successForgot.config
      );
    } else {
      this.userSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  }

  private errorForgot = (error: HttpErrorResponse): void => {
    this.userSubject.next(null);
    if (
      this.commonsHttp.errorsHttp.isControlledError(error) &&
      this.commonsHttp.errorsHttp.isErrorCode(
        error,
        'E0008',
        `You don't have access permission to see this information.`,
        'Tu rol es diferente a la plataforma de donde estas accediendo'
      )
    ) {
      this.allApp.snackbar.open(
        OAuthSnackbars.failureForgotRole.message,
        OAuthSnackbars.failureForgotRole.closeBtn,
        OAuthSnackbars.failureForgotRole.config
      );
    } else if (
      this.commonsHttp.errorsHttp.isControlledError(error) &&
      this.commonsHttp.errorsHttp.isErrorCode(
        error,
        'E0012',
        'Invalid credentials.',
        ''
      )
    ) {
      this.allApp.snackbar.open(
        this.allApp.translate.instant(OAuthSnackbars.failureForgot.message),
        OAuthSnackbars.failureForgot.closeBtn,
        OAuthSnackbars.failureForgot.config
      );
    } else if (
      this.commonsHttp.errorsHttp.isControlledError(error) &&
      this.commonsHttp.errorsHttp.isErrorCode(
        error,
        'E0013',
        'Resource is CANCELLED.',
        'Cuenta cancelada. No se puede hacer la recuperación de la contraseña'
      )
    ) {
      this.allApp.snackbar.open(
        this.allApp.translate.instant(
          OAuthSnackbars.failureAccountCancelled.message
        ),
        OAuthSnackbars.failureAccountCancelled.closeBtn,
        OAuthSnackbars.failureAccountCancelled.config
      );
    } else if (
      this.commonsHttp.errorsHttp.isControlledError(error) &&
      this.commonsHttp.errorsHttp.isErrorCode(
        error,
        'E0014',
        'Resource is INACTIVE.',
        'Cuenta inactiva. No se puede hacer la recuperación de la contraseña'
      )
    ) {
      this.allApp.snackbar.open(
        this.allApp.translate.instant(
          OAuthSnackbars.failureAccountInactive.message
        ),
        OAuthSnackbars.failureAccountInactive.closeBtn,
        OAuthSnackbars.failureAccountInactive.config
      );
    } else if (
      this.commonsHttp.errorsHttp.isControlledError(error) &&
      this.commonsHttp.errorsHttp.isErrorCode(
        error,
        'E0020',
        'Resource is CREATED.',
        'Cuenta no validada. No se puede hacer la recuperación de la contraseña'
      )
    ) {
      this.allApp.snackbar.open(
        OAuthSnackbars.failureLoginCreated.message,
        OAuthSnackbars.failureLoginCreated.closeBtn,
        OAuthSnackbars.failureLoginCreated.config
      );
    }
    this.commonsHttp.errorsHttp.communication(error);
  }

  // ==========================================================================================
  // Reset Password
  // ==========================================================================================

  public resetPassword(
    userID: number | string,
    resetPasswordJSON: ResetPasswordJson
  ): void {
    this.enableLoading();
    this.oAuthHttp
      .resetPassword$(userID, resetPasswordJSON)
      .pipe(finalize(() => this.disableLoading()))
      .subscribe({
        next: this.nextResetPassword,
        error: this.errorResetPassword,
      });
  }

  private nextResetPassword = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus201(data)) {
      const user: User = data.body.result[0];
      this.userSubject.next(user);
      this.allApp.snackbar.open(
        this.allApp.translate.instant(
          OAuthSnackbars.successResetPassword.message
        ),
        OAuthSnackbars.successResetPassword.closeBtn,
        OAuthSnackbars.successResetPassword.config
      );
    } else {
      this.userSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  }

  private errorResetPassword = (error: HttpErrorResponse): void => {
    this.userSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  }

  // ==========================================================================================
  // Otp
  // ==========================================================================================

  public otp(userID: number | string): void {
    this.enableLoading();
    this.oAuthHttp
      .otp$(userID)
      .pipe(finalize(() => this.disableLoading()))
      .subscribe({ next: this.nextOtp, error: this.errorOtp });
  }

  private nextOtp = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const user: User = data.body.result[0];
      this.userSubject.next(user);
    } else {
      this.userSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  }

  private errorOtp = (error: HttpErrorResponse): void => {
    this.userSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  }

  // ==========================================================================================
  // Otp with Username
  // ==========================================================================================

  public otpUsername(otpUsernameJSON: OtpUsernameJson): void {
    this.enableLoading();
    this.oAuthHttp
      .otpUsername$(otpUsernameJSON)
      .pipe(finalize(() => this.disableLoading()))
      .subscribe({ next: this.nextOtpUsername, error: this.errorOtpUsername });
  }

  private nextOtpUsername = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const user: User = data.body.result[0];
      this.userSubject.next(user);
    } else {
      this.userSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  }

  private errorOtpUsername = (error: HttpErrorResponse): void => {
    this.userSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  }

  // ==========================================================================================
  // Change Password
  // ==========================================================================================

  public changePassword(changePasswordJSON: ChangePasswordJson): void {
    if (this.oAuthStorage.hasOAuth && this.oAuthStorage.getUserID) {
      this.enableLoading();
      this.oAuthHttp
        .changePassword$(this.oAuthStorage.getUserID, changePasswordJSON)
        .pipe(finalize(() => this.disableLoading()))
        .subscribe({
          next: this.nextChangePassword,
          error: this.errorChangePassword,
        });
    }
  }

  private nextChangePassword = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const user: User = data.body.result[0];
      this.userSubject.next(user);
      this.allApp.snackbar.open(
        this.allApp.translate.instant(
          OAuthSnackbars.successResetPassword.message
        ),
        OAuthSnackbars.successResetPassword.closeBtn,
        OAuthSnackbars.successResetPassword.config
      );
    } else {
      this.userSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  }

  private errorChangePassword = (error: HttpErrorResponse): void => {
    if (
      this.commonsHttp.errorsHttp.isControlledError(error) &&
      this.commonsHttp.errorsHttp.isErrorCode(
        error,
        'E0004',
        'A required field is missing.',
        'oldPassword es inválida'
      )
    ) {
      this.allApp.snackbar.open(
        this.allApp.translate.instant(
          OAuthSnackbars.failureChangePasswordOldPassword.message
        ),
        OAuthSnackbars.failureChangePasswordOldPassword.closeBtn,
        OAuthSnackbars.failureChangePasswordOldPassword.config
      );
    } else if (
      this.commonsHttp.errorsHttp.isControlledError(error) &&
      this.commonsHttp.errorsHttp.isErrorCode(
        error,
        'E0004',
        'A required field is missing.',
        'The new password and old password must be different.'
      )
    ) {
      this.allApp.snackbar.open(
        this.allApp.translate.instant(
          OAuthSnackbars.failureChangePasswordSame.message
        ),
        OAuthSnackbars.failureChangePasswordSame.closeBtn,
        OAuthSnackbars.failureChangePasswordSame.config
      );
    } else {
      this.allApp.snackbar.open(
        this.allApp.translate.instant(
          OAuthSnackbars.failureChangePassword.message
        ),
        OAuthSnackbars.failureChangePassword.closeBtn,
        OAuthSnackbars.failureChangePassword.config
      );
    }
    this.userSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  }

  // ==========================================================================================
  // Get User Registers
  // ==========================================================================================

  public getUserRegister(userID: number | string): void {
    this.enableLoading();
    this.oAuthHttp
      .getUserRegister$(userID)
      .pipe(finalize(() => this.disableLoading()))
      .subscribe({
        next: this.nextGetUserRegister,
        error: this.errorGetUserRegister,
      });
  }

  private nextGetUserRegister = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const user: User = data.body.result[0];
      this.userSubject.next(user);
    } else {
      this.userSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  }

  private errorGetUserRegister = (error: HttpErrorResponse): void => {
    this.userSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  }

  // ==========================================================================================
  // Update User Phone
  // ==========================================================================================

  public updateUserRegister(
    userID: number | string,
    updatePhoneJSON: UserDataRegisterJson
  ): void {
    this.enableLoading();
    this.oAuthHttp
      .updateUserRegister$(userID, updatePhoneJSON)
      .pipe(finalize(() => this.disableLoading()))
      .subscribe({
        next: this.nextUpdateUserRegister,
        error: this.errorUpdateUserRegister,
      });
  }

  private nextUpdateUserRegister = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const user: User = data.body.result[0];
      this.userSubject.next(user);
    } else {
      this.userSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  }

  private errorUpdateUserRegister = (error: HttpErrorResponse): void => {
    this.userSubject.next(null);
    if (
      this.commonsHttp.errorsHttp.isControlledError(error) &&
      this.commonsHttp.errorsHttp.isErrorCode(
        error,
        'E0006',
        'The record already exists.',
        'El correo/móvil ya existe'
      )
    ) {
      this.allApp.snackbar.open(
        this.allApp.translate.instant(
          OAuthSnackbars.failureChangePhone.message
        ),
        OAuthSnackbars.failureChangePhone.closeBtn,
        OAuthSnackbars.failureChangePhone.config
      );
    }
    this.commonsHttp.errorsHttp.communication(error);
  }
}
