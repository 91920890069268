import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OAuthStorageService } from '@qaroni-app/application/auth';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OAuthInterceptor implements HttpInterceptor {
  constructor(private oAuthStorage: OAuthStorageService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let authReq = req;

    if (this.oAuthStorage.hasOAuth) {
      const headers = req.headers.set(
        'Authorization',
        'Bearer ' + this.oAuthStorage.get().access_token
      );
      authReq = req.clone({ headers });
    }
    return next.handle(authReq);
  }
}
