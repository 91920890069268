export enum LanguageEnum {
  default = 'es',
  es = 'es',
  gl = 'gl',
}

export type LanguageEnumType = LanguageEnum.es | LanguageEnum.gl;

export const LanguageEnumArray = [LanguageEnum.es, LanguageEnum.gl];

export const LanguageEnumInfo = [
  {
    [LanguageEnum.es]: {
      name: 'Español',
    },
  },
  {
    [LanguageEnum.gl]: {
      name: 'Galego',
    },
  },
];
