export enum MerchantStatusEnum {
  ACTIVE = 'ACTIVE',
  CANCELLED = 'CANCELLED',
  INACTIVE = 'INACTIVE',
}

export type MerchantStatusType =
  | MerchantStatusEnum.ACTIVE
  | MerchantStatusEnum.CANCELLED
  | MerchantStatusEnum.INACTIVE;

export const MerchantStatusArray = [
  MerchantStatusEnum.ACTIVE,
  MerchantStatusEnum.CANCELLED,
  MerchantStatusEnum.INACTIVE,
];

export const MerchantStatusInfo = [
  {
    [MerchantStatusEnum.ACTIVE]: {
      name: 'Activo',
    },
  },
  {
    [MerchantStatusEnum.CANCELLED]: {
      name: 'Cancelado',
    },
  },
  {
    [MerchantStatusEnum.INACTIVE]: {
      name: 'Inactivo',
    },
  },
];
