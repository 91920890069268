import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'qaroni-input-error-messages',
  templateUrl: './input-error-messages.component.html',
  styleUrls: ['./input-error-messages.component.scss'],
})
export class InputErrorMessagesComponent implements OnInit {
  @Input() fc: FormControl;

  @Input() aditionalString: any;

  public stringHelper: string;

  constructor() {}

  ngOnInit(): void {
    if (this.aditionalString && typeof this.aditionalString === 'string') {
      this.stringHelper = this.aditionalString;
    }
  }
}
