export enum TransactionResultEnum {
  ACCEPTED = 'ACCEPTED',
  CANCELLED = 'CANCELLED',
  DENIED = 'DENIED',
}

export type TransactionResultType =
  | TransactionResultEnum.ACCEPTED
  | TransactionResultEnum.CANCELLED
  | TransactionResultEnum.DENIED;

export const TransactionResultArray = [
  TransactionResultEnum.ACCEPTED,
  TransactionResultEnum.CANCELLED,
  TransactionResultEnum.DENIED,
];

export const TransactionResultInfo = [
  {
    [TransactionResultEnum.ACCEPTED]: {
      name: 'Aceptada',
    },
  },
  {
    [TransactionResultEnum.CANCELLED]: {
      name: 'Cancelada',
    },
  },
  {
    [TransactionResultEnum.DENIED]: {
      name: 'Denegada',
    },
  },
];
