export enum UserStatusEnum {
  ACTIVE = 'ACTIVE',
  CANCELLED = 'CANCELLED',
  CREATED = 'CREATED',
  INACTIVE = 'INACTIVE',
}

export type UserStatusType =
  | UserStatusEnum.ACTIVE
  | UserStatusEnum.CANCELLED
  | UserStatusEnum.CREATED
  | UserStatusEnum.INACTIVE;

export const UserStatusArray = [
  UserStatusEnum.ACTIVE,
  UserStatusEnum.CANCELLED,
  UserStatusEnum.CREATED,
  UserStatusEnum.INACTIVE,
];

export const UserStatusInfo = [
  {
    [UserStatusEnum.ACTIVE]: {
      name: 'Active',
    },
  },
  {
    [UserStatusEnum.CANCELLED]: {
      name: 'Cancelled',
    },
  },
  {
    [UserStatusEnum.CREATED]: {
      name: 'Created',
    },
  },
  {
    [UserStatusEnum.INACTIVE]: {
      name: 'Inactive',
    },
  },
];
