import { Component, OnInit } from '@angular/core';
import { ImagesConfig } from '@qaroni-core/config';

@Component({
  selector: 'qaroni-outside-footer',
  templateUrl: './outside-footer.component.html',
  styleUrls: ['./outside-footer.component.scss'],
})
export class OutsideFooterComponent implements OnInit {
  public ImagesConfig = ImagesConfig;

  constructor() {}

  ngOnInit(): void {}
}
