import { Pipe, PipeTransform } from '@angular/core';
import { CardTypeEnum, CardTypeInfo } from '@qaroni-core/entities';

@Pipe({
  name: 'cardType',
})
export class CardTypePipe implements PipeTransform {
  transform(value: CardTypeEnum): string {
    if (value) {
      const key = value.trim().toUpperCase();
      for (const iterator of CardTypeInfo) {
        if (Object.keys(iterator)[0] === key) {
          return iterator[key].name;
        }
      }
    }
    return null;
  }
}
