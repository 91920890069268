import { transition, trigger, useAnimation } from '@angular/animations';
import {
  qaroniToggleHeightEnterConfig,
  qaroniToggleHeightLeaveConfig
} from './toggle-height';

export const qaroniToggleHeightTrigger = trigger('toggleHeight', [
  transition(':enter', [
    useAnimation(qaroniToggleHeightEnterConfig, { params: { time: '350ms' } }),
  ]),
  transition(':leave', [
    useAnimation(qaroniToggleHeightLeaveConfig, { params: { time: '350ms' } }),
  ]),
]);
