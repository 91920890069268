import { animate, animation, style } from '@angular/animations';

export const qaroniToggleHeightEnterConfig = animation([
  style({ height: '0px', overflow: 'hidden' }),
  animate('{{time}} cubic-bezier(0.4, 0.0, 0.2, 1)', style({ height: '*' })),
]);

export const qaroniToggleHeightLeaveConfig = animation([
  style({ height: '*', overflow: 'hidden' }),
  animate('{{time}} cubic-bezier(0.4, 0.0, 0.2, 1)', style({ height: '0px' })),
]);
