import { Component, Input, OnInit } from '@angular/core';
import { Merchant } from '@qaroni-app/application/merchants';
import { qaroniToggleFadeTrigger } from '@qaroni-shared/animations';

@Component({
  selector: 'qaroni-merchant-preview',
  templateUrl: './merchant-preview.component.html',
  styleUrls: ['./merchant-preview.component.scss'],
  animations: [qaroniToggleFadeTrigger],
})
export class MerchantPreviewComponent implements OnInit {
  @Input() merchant: Merchant;

  constructor() {}

  ngOnInit(): void {}
}
